import type { IFormCallMasterModel } from '~/components/M/Forms/MFormCallMaster/MFormCallMaster.model';

export interface IFormFAQQuestionModel {
  type: Record<string, any> | null;
  name: string | number | Date | null;
  email: string | number | Date | null;
  city: string | number | Date | null;
  question: string | number | null;
  captcha?: string | null;
  captcha_key?: string | null;
}

/**
 * @description Question form model
 */
export class FormFAQQuestionModel {
  public type: IFormFAQQuestionModel['type'];
  public name: IFormFAQQuestionModel['name'];
  public email: IFormFAQQuestionModel['email'];
  public city: IFormFAQQuestionModel['city'];
  public question: IFormFAQQuestionModel['question'];
  public captcha: IFormCallMasterModel['captcha'];
  public captcha_key: IFormCallMasterModel['captcha_key'];

  constructor(attrs: Partial<IFormFAQQuestionModel> | null = {}, enableCaptcha: boolean = false) {
    attrs = attrs ?? {};

    this.type = attrs.type || null;
    this.name = attrs.name || null;
    this.email = attrs.email || null;
    this.city = attrs.city || null;
    this.question = attrs.question || null;

    if (enableCaptcha) {
      this.captcha = attrs.captcha || null;
      this.captcha_key = attrs.captcha_key || null;
    }
  }
  public get captchaEnabled() {
    return this.captcha;
  }

  public set captchaEnabled(captcha: IFormCallMasterModel['captcha']) {
    this.captcha = captcha;
  }

  public reset() {
    this.type = null;
    this.name = null;
    this.email = null;
    this.city = null;
    this.question = null;
    this.captcha = this.captchaEnabled ? null : undefined;
    this.captcha_key = this.captchaEnabled ? null : undefined;
  }

  public toJSON(): IFormFAQQuestionModel {
    return {
      type: this.type,
      name: this.name,
      email: this.email,
      city: this.city,
      question: this.question,
      captcha: this.captchaEnabled ? this.captcha : undefined,
      captcha_key: this.captchaEnabled ? this.captcha_key : undefined,
    };
  }
}
